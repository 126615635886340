"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultSlippageDecimalPercentageForSwapper = exports.swappers = exports.QUOTE_TIMEOUT_ERROR = exports.TRADE_POLL_INTERVAL_MILLISECONDS = exports.QUOTE_TIMEOUT_MS = exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const ArbitrumBridgeSwapper_1 = require("./swappers/ArbitrumBridgeSwapper/ArbitrumBridgeSwapper");
const endpoints_1 = require("./swappers/ArbitrumBridgeSwapper/endpoints");
const constants_1 = require("./swappers/ArbitrumBridgeSwapper/utils/constants");
const CowSwapper_1 = require("./swappers/CowSwapper/CowSwapper");
const endpoints_2 = require("./swappers/CowSwapper/endpoints");
const constants_2 = require("./swappers/CowSwapper/utils/constants");
const endpoints_3 = require("./swappers/LifiSwapper/endpoints");
const LifiSwapper_1 = require("./swappers/LifiSwapper/LifiSwapper");
const constants_3 = require("./swappers/LifiSwapper/utils/constants");
const constants_4 = require("./swappers/PortalsSwapper/constants");
const endpoints_4 = require("./swappers/PortalsSwapper/endpoints");
const PortalsSwapper_1 = require("./swappers/PortalsSwapper/PortalsSwapper");
const constants_5 = require("./swappers/ThorchainSwapper/constants");
const endpoints_5 = require("./swappers/ThorchainSwapper/endpoints");
const ThorchainSwapper_1 = require("./swappers/ThorchainSwapper/ThorchainSwapper");
const endpoints_6 = require("./swappers/ZrxSwapper/endpoints");
const constants_6 = require("./swappers/ZrxSwapper/utils/constants");
const ZrxSwapper_1 = require("./swappers/ZrxSwapper/ZrxSwapper");
const types_1 = require("./types");
const utils_2 = require("./utils");
exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL = 20000;
exports.QUOTE_TIMEOUT_MS = 60000;
exports.TRADE_POLL_INTERVAL_MILLISECONDS = 10000;
exports.QUOTE_TIMEOUT_ERROR = (0, utils_2.makeSwapErrorRight)({
    message: `quote timed out after ${exports.QUOTE_TIMEOUT_MS / 1000}s`,
});
// PartialRecord not used to ensure exhaustiveness
exports.swappers = {
    [types_1.SwapperName.LIFI]: {
        ...LifiSwapper_1.lifiSwapper,
        ...endpoints_3.lifiApi,
        supportedChainIds: constants_3.LIFI_SUPPORTED_CHAIN_IDS,
        pollingInterval: LifiSwapper_1.LIFI_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Thorchain]: {
        ...ThorchainSwapper_1.thorchainSwapper,
        ...endpoints_5.thorchainApi,
        supportedChainIds: constants_5.THORCHAIN_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Zrx]: {
        ...ZrxSwapper_1.zrxSwapper,
        ...endpoints_6.zrxApi,
        supportedChainIds: constants_6.ZRX_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.CowSwap]: {
        ...CowSwapper_1.cowSwapper,
        ...endpoints_2.cowApi,
        supportedChainIds: constants_2.COW_SWAP_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.ArbitrumBridge]: {
        ...ArbitrumBridgeSwapper_1.arbitrumBridgeSwapper,
        ...endpoints_1.arbitrumBridgeApi,
        supportedChainIds: constants_1.ARBITRUM_BRIDGE_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Portals]: {
        ...PortalsSwapper_1.portalsSwapper,
        ...endpoints_4.portalsApi,
        supportedChainIds: constants_4.PORTALS_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Test]: undefined,
};
// Slippage defaults. Don't export these to ensure the getDefaultSlippageDecimalPercentageForSwapper helper function is used.
const DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE = '0.002'; // .2%
const DEFAULT_COWSWAP_SLIPPAGE_DECIMAL_PERCENTAGE = '0.005'; // .5%
const DEFAULT_PORTALS_SLIPPAGE_DECIMAL_PERCENTAGE = '0.01'; // 1%
const DEFAULT_LIFI_SLIPPAGE_DECIMAL_PERCENTAGE = '0.005'; // .5%
const DEFAULT_THOR_SLIPPAGE_DECIMAL_PERCENTAGE = '0.01'; // 1%
const DEFAULT_ARBITRUM_BRIDGE_SLIPPAGE_DECIMAL_PERCENTAGE = '0'; // no slippage for Arbitrum Bridge, so no slippage tolerance
const getDefaultSlippageDecimalPercentageForSwapper = (swapperName) => {
    if (swapperName === undefined)
        return DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE;
    switch (swapperName) {
        case types_1.SwapperName.Zrx:
        case types_1.SwapperName.Test:
            return DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.LIFI:
            return DEFAULT_LIFI_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.CowSwap:
            return DEFAULT_COWSWAP_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Portals:
            return DEFAULT_PORTALS_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Thorchain:
            return DEFAULT_THOR_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.ArbitrumBridge:
            return DEFAULT_ARBITRUM_BRIDGE_SLIPPAGE_DECIMAL_PERCENTAGE;
        default:
            (0, utils_1.assertUnreachable)(swapperName);
    }
};
exports.getDefaultSlippageDecimalPercentageForSwapper = getDefaultSlippageDecimalPercentageForSwapper;
